import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ourPartnersIcon from 'assets/images/ourPartnersIcon.svg';
import { Arrow } from 'components/v2/common/Arrow';
import Slider from 'react-slick';
import { Card } from './Card';
import freshworks from 'assets/images/freshworks.png';
import thousandPlusEntrepreneurs from 'assets/images/thousandPlusEntrepreneurs.png';
import padUpVentures from 'assets/images/padUpVentures.png';
import meteorVenture from 'assets/images/meteorVenture.png';
import theStartupLab from 'assets/images/theStartupLab.png';
import carveStartupLabs from 'assets/images/carveStartupLabs.png';

export const OurPartners = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const SlickArrowLeft = ({ ...props }) => (
    <Box
      left={-75}
      top="50%"
      sx={{ transform: 'translate(0,-50%)' }}
      position="absolute"
      zIndex={100}
    >
      <Arrow direction="left" {...props} />
    </Box>
  );

  const SlickArrowRight = ({ ...props }) => (
    <Box
      right={-75}
      top="50%"
      sx={{ transform: 'translate(0,-50%)' }}
      position="absolute"
      zIndex={100}
    >
      <Arrow direction="right" {...props} />
    </Box>
  );

  const settings = {
    dots: matches,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    centerPadding: '0',
    arrows: !matches,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box
      bgcolor="#F8FBF5"
      sx={{
        pt: {
          xs: '25px',
          md: '72px',
        },
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          bgcolor: '#E6F0F1',
          maxWidth: {
            xs: '75%',
          },
        }}
      >
        <Box display="flex" alignItems="center" mb="75px">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            // width={75}
            // height={75}
            mr={3}
            ml={1.5}
            sx={{
              '& img': {
                width: '100%',
              },
              height: {
                xs: 50,
                md: 75,
              },
              width: {
                xs: 50,
                md: 75,
              },
            }}
          >
            <img src={ourPartnersIcon} alt="why pod icon" />
          </Box>
          <Box flexGrow={1}>
            <Typography
              fontWeight={600}
              // fontSize={24}
              color="#FF6446"
              letterSpacing="0.01em"
              mb="10px"
              // mt="40px"
              sx={{
                fontSize: {
                  xs: 16,
                  md: 24,
                },
                mt: {
                  xs: '20px',
                  md: '40px',
                },
              }}
            >
              We Build Trust
            </Typography>
            <Typography
              fontWeight={900}
              // fontSize={40}
              color="#004643"
              mb="19px"
              sx={{
                fontSize: {
                  xs: 24,
                  md: 40,
                },
              }}
            >
              Our Partners
            </Typography>
          </Box>
        </Box>
      </Container>
      <Box position="relative">
        <Container
          disableGutters
          maxWidth="md"
          sx={{ bgcolor: '#F8FBF5', pb: '120px', maxWidth: '80%' }}
        >
          <Slider {...settings}>
            <Card
              bgcolor="rgba(236, 110, 95, 0.2)"
              alt="Freshworks logo"
              img={freshworks}
            />
            <Card
              bgcolor="rgba(186, 232, 232, 0.2)"
              alt="Thousand Plus Entrepreneurs logo"
              img={thousandPlusEntrepreneurs}
            />
            <Card
              bgcolor="rgba(236, 110, 95, 0.2)"
              alt="Pad Up Ventures logo"
              img={padUpVentures}
            />
            <Card
              bgcolor="rgba(186, 232, 232, 0.2)"
              alt="Meteor Venture logo"
              img={meteorVenture}
            />
            <Card
              bgcolor="rgba(236, 110, 95, 0.2)"
              alt="The Startup Lab logo"
              img={theStartupLab}
            />
            <Card
              bgcolor="rgba(186, 232, 232, 0.2)"
              alt="Carve Startup Labs logo"
              img={carveStartupLabs}
            />
            <Card
              bgcolor="rgba(236, 110, 95, 0.2)"
              alt="Freshworks logo"
              img={freshworks}
            />
            <Card
              bgcolor="rgba(186, 232, 232, 0.2)"
              alt="Thousand Plus Entrepreneurs logo"
              img={thousandPlusEntrepreneurs}
            />
            <Card
              bgcolor="rgba(236, 110, 95, 0.2)"
              alt="Pad Up Ventures logo"
              img={padUpVentures}
            />

            {/* <Card bgcolor="orange" alt="orange" img={achievementFlag} />
            <Card bgcolor="orange" alt="orange" img={achievementFlag} />
            <Card bgcolor="orange" alt="orange" img={achievementFlag} /> */}
            {/* <img src={OurPartnersImgEight} alt="Freshworks logo" />
          <img src={OutPartnersImgFive} alt="Freshworks logo" />
          <img src={OutPartnersImgThree} alt="Freshworks logo" />
          <img src={OutPartnersImgFour} alt="Freshworks logo" />
          <img src={OutPartnersImgTwo} alt="Freshworks logo" />
          <img src={OutPartnersImgOne} alt="Freshworks logo" />
          <img src={OutPartnersImgSix} alt="Freshworks logo" />
          <img src={OutPartnersImgSeven} alt="Freshworks logo" /> */}
          </Slider>
        </Container>
      </Box>
    </Box>
  );
};

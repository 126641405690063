import { Box, Typography } from '@mui/material';
import Link from 'components/Link';
import Image from 'next/image';
import imageKitLoader from 'utils/loader';

interface MediaCardProps {
    cover_image: string;
    headline: string;
    media_name: string;
    link: string
}

export const MediaCard = ({
    headline,
    cover_image,
    media_name,
    link
}: MediaCardProps) => {

    return (
        <>
            <Link
                noLinkStyle
                href={link}
                sx={{
                    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                }}
            >
                <Box bgcolor="#fff" borderRadius="8px" overflow="hidden">
                    <Box
                        sx={{
                            aspectRatio: '1.8',
                            borderRadius: '8px',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            position: 'relative',
                        }}
                    >
                        {cover_image && (
                            <Image
                                loader={imageKitLoader}
                                alt="cover"
                                src={cover_image.split('com')[1]}
                                layout="fill"
                            />
                        )}
                    </Box>
                    <Box px={4} pt={2}>
                        <Box display="flex" minHeight="90px">
                            <Typography
                                component="h2"
                                color="#272343"
                                fontWeight={600}
                                fontSize={16}
                                ml={1.5}
                            >
                                {headline}
                            </Typography>
                        </Box>
                    </Box>
                    <Box px={4} mt="30px" py={1} borderRadius="8px" bgcolor="#F5F7FB">
                        <Typography
                            bgcolor="#DFE1E6"
                            borderRadius="3px"
                            sx={{ textTransform: 'uppercase' }}
                            component="span"
                            px={0.5}
                            py={0.2}
                            letterSpacing="-0.0784424px"
                            fontWeight={500}
                            fontSize={11.2}
                            color="#42526E"
                        >
                            {media_name}
                        </Typography>
                    </Box>
                </Box>
            </Link>
        </>
    );
};

import { Box, Container, Typography } from '@mui/material';

export const MediaHeader = () => {
  return (
    <Container disableGutters>
      <Typography
        component="h2"
        color="#1E3841"
        fontWeight={700}
        letterSpacing="0.01em"
        mt="94px"
        // fontSize={36}
        sx={{
          pl: {
            xs: 1.5,
            md: 0,
          },
          fontSize: {
            xs: 20,
            md: 36,
          },
          mt: {
            xs: '50px',
            md: '94px',
          },
        }}
      >
        POD in the News!
      </Typography>
      <Box
        width="56px"
        borderRadius={10}
        height="6px"
        mt={2}
        bgcolor="#FF6446"
        sx={{
          ml: {
            xs: 1.5,
            md: 0,
          },
        }}
      />
    </Container>
  );
};

import { Box } from '@mui/material';

interface CardProps {
  bgcolor: string;
  img: string;
  alt: string;
}

export const Card = ({ bgcolor, img, alt }: CardProps) => {
  return (
    <Box p={2}>
      <Box
        bgcolor={bgcolor}
        // height={153}
        // width={153}
        sx={{
          height: {
            xs: 130,
            md: 153,
          },
          width: {
            xs: 130,
            md: 153,
          },
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        boxShadow="10px 10px 0 #DFE5DF"
      >
        <img src={img} alt={alt} />
      </Box>
    </Box>
  );
};

import { Box, Container, Grid } from '@mui/material';
import { Heading1 } from 'components/v2/common/Heading1';
import achievementFlag from 'assets/images/achievementFlag.svg';
import donate from 'assets/images/donate.svg';
import idea from 'assets/images/idea.svg';
import peopleGroup from 'assets/images/peopleGroup.svg';
import like from 'assets/images/like.svg';
import { Element } from './Element';
import { Carousel } from 'react-responsive-carousel';
import chevronRight from 'assets/images/chevronRight.svg';

export const Achievements = () => {
  return (
    <Box bgcolor="#DAEFF1" component="section">
      <Box mx="auto" pt="44px" width="500px" maxWidth="80%">
        <Heading1 icon={achievementFlag}>Achievements</Heading1>
      </Box>
      <Container
        disableGutters
        sx={{
          display: {
            xs: 'none',
            md: 'inherit',
          },
        }}
      >
        <Grid container mt="54px" pb="40px">
          <Grid item lg={3} display="flex" justifyContent="center">
            <Element title="5000+" content="Investors" icon={peopleGroup} />
          </Grid>
          <Grid item lg={3} display="flex" justifyContent="center">
            <Element title="500+" content="Startups" icon={idea} />
          </Grid>
          <Grid item lg={3} display="flex" justifyContent="center">
            <Element title="₹20 Cr. +" content="Transacted" icon={like} />
          </Grid>
          <Grid item lg={3} display="flex" justifyContent="center">
            <Element title="250+" content="Investments" icon={donate} />
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={{
          display: {
            xs: 'inherit',
            md: 'none',
          },
        }}
        pt={6}
        pb={8}
      >
        <Carousel
          showStatus={false}
          showThumbs={false}
          showIndicators={false}
          infiniteLoop
          renderArrowNext={(onClickHandler) => (
            <Box
              position="absolute"
              top="50%"
              sx={{
                transform: 'translate(0, -50%)',
                '& img': {
                  height: 14,
                },
              }}
              right={50}
              zIndex={20}
              onClick={onClickHandler}
              p={1}
            >
              <img src={chevronRight} alt="arrow right" />
            </Box>
          )}
          renderArrowPrev={(onClickHandler) => (
            <Box
              position="absolute"
              top="50%"
              sx={{
                transform: 'translate(0, -50%) scale(-1,1)',
                '& img': {
                  height: 14,
                },
              }}
              left={50}
              zIndex={20}
              onClick={onClickHandler}
              p={1}
            >
              <img src={chevronRight} alt="arrow right" />
            </Box>
          )}
        >
          <Box display="flex" justifyContent="center">
            <Element title="2000+" content="Investors" icon={peopleGroup} />
          </Box>
          <Box display="flex" justifyContent="center">
            <Element title="500+" content="Startups" icon={idea} />
          </Box>
          <Box display="flex" justifyContent="center">
            <Element title="₹20 Cr. +" content="Transacted" icon={like} />
          </Box>
          <Box display="flex" justifyContent="center">
            <Element title="250+" content="Investments" icon={donate} />
          </Box>
        </Carousel>
      </Box>
    </Box>
  );
};
